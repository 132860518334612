// src/pages/Help.js
import React, { useEffect, useState } from 'react';
import content from '../data/data.json';
import '../styles/Vendors.css';
import Glossary from '../components/Glossary';

function Vendors() {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(content.help);
  }, []);

  return (
    <div className="help">
      <section className="help-hero">
        
      </section>
      <Glossary />
      <div className="container" style={{ display: "none" }}>
        <section className="help-content">
          <h1>{data.title}</h1>
          <p>{data.description}</p>
          {data.content && data.content.map((item, index) => (
            <div key={index} className="help-section">
              <h2>{item.heading}</h2>
              <p>{item.text}</p>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}

export default Vendors;
