import React, { useState } from 'react';
import axios from 'axios';
import '../styles/Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Phone: '',
    Company: '',
    Position: '',
    Subject: '',
    Message: ''
  });

  // פונקציה לשליחת טופס פניה לשרת
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Form data:', formData);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contactus`, formData);
      alert(response.data.message);
      // איפוס הטופס לאחר שליחה מוצלחת
      setFormData({
        Name: '',
        Email: '',
        Phone: '',
        Company: '',
        Position: '',
        Subject: '',
        Message: ''
      });
    } catch (error) {
      alert('Failed to send contact message.');
    }
  };

  // טיפול בשינויי קלט בטופס
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="contact">
      <section className="contact-hero">
        {/* You can add an image or background here */}
      </section>
      <div className="container">     
          <div className="contact-form">
            <h2>Drop us a line!</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name*</label>
                <input type="text" name="Name" value={formData.Name} onChange={handleChange} placeholder="Name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input type="email" name="Email" value={formData.Email} onChange={handleChange} placeholder="Email" required />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone*</label>
                <input type="text" name="Phone" value={formData.Phone} onChange={handleChange} placeholder="Phone" />
              </div>
              <div className="form-group">
                <label htmlFor="company">Company*</label>
                <input type="text" name="Company" value={formData.Company} onChange={handleChange} placeholder="Company" />
              </div>
              <div className="form-group">
                <label htmlFor="position">Position*</label>
                <input type="text" name="Position" value={formData.Position} onChange={handleChange} placeholder="Position" />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject*</label>
                <input type="text" name="Subject" value={formData.Subject} onChange={handleChange} placeholder="Subject" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea name="Message" value={formData.Message} onChange={handleChange} placeholder="Message" required></textarea>
              </div>
              <button type="submit">Send</button>
            </form>
          </div>
      </div>
    </div>
  );
}

export default Contact;
